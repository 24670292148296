<template>
    <table class="ui very basic striped table">
        <thead>
        <tr class="">
            <th class="five wide">Désignation</th>
            <th class="one wide">Quantité</th>
            <th class="two wide">Type</th>
            <th class="two wide">TVA</th>
            <th class="two wide">Prix unitaire</th>
            <th class="one wide">Vétusté</th>
            <th class="two wide">Montant</th>
            <th class="one wide"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in working_items" class="">
            <td class="collapsing">{{item.designation}}</td>
            <td>{{item.quantite}}</td>
            <td>{{item.type}}</td>
            <td>{{item.tva}}</td>
            <td>{{item.prix_unitaire}}</td>
            <td>{{item.vetuste}}</td>
            <td>{{item.montant ? item.montant.toFixed(2): ''}}</td>
<!--            <td>{{item.montant}}</td>-->
            <td :class="{'disabled': isDisabled}" class="collapsing">
                <i class="pencil green icon" style="cursor: pointer" title="Editer" @click="editItem(index)"></i>
                <i class="x red icon" style="cursor: pointer" title="Supprimer" @click="removeItem(index)"></i>
            </td>
        </tr>

        <tr class="warning" v-show="working_items.length">
            <td> Total </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> {{total_fourniture?.total_vetuste?.toFixed(2) || 0}} </td>
            <td> {{total_fourniture?.total_montant?.toFixed(2) || 0}} </td>
            <td> </td>
        </tr>

        <tr class="active">

                <td class="collapsing">
                    <!--<input type="text" v-model="current_item.designation" placeholder="désignation"
                           v-uppercase :disabled="isDisabled">-->
                    <v-select taggable :options="designation_list" :label="'label'" v-model="current_item.designation" :reduce="desig => desig.label"
                              placeholder="désignation" :disabled="isDisabled">
                    </v-select>
                </td>

                <td>
                    <input type="number" v-model.number="current_item.quantite" placeholder="quantité" :disabled="isDisabled">
                </td>
                <td>
                    <!--<input type="text" v-model="current_item.type" placeholder="type">-->
                    <v-select :options="['A', 'O', 'R']" v-model="current_item.type" :clearable="false"
                              style="width: max-content" placeholder="type" :disabled="isDisabled">
                    </v-select>
                </td>

                <td>
                    <v-select :options="['Oui', 'Non']" v-model="current_item.tva" :clearable="false"
                              style="width: max-content" placeholder="type" :disabled="isDisabled">
                    </v-select>
                </td>

                <td class="collapsing">
                    <input type="number" v-model.number="current_item.prix_unitaire"
                           placeholder="prix unitaire" :disabled="isDisabled">
                </td>

                <td>
                    <input type="number" v-model.number="current_item.vetuste" placeholder="vétusté" :disabled="isDisabled">
                </td>

                <td class="collapsing">
                    <input type="text" :value="total" placeholder="montant" disabled>
                </td>

                <td :class="{'disabled': isDisabled}" class="collapsing">
                    <i v-if="editingItem" class="pencil blue icon" style="cursor: pointer" title="Editer" @click="saveEditItem"></i>
                    <i v-else class="plus green icon" style="cursor: pointer" title="Ajouter l'element" @click="addItem"></i>
                </td>

        </tr>
        </tbody>

    </table>
</template>

<script>
    export default {
        name: "detail_fourniture",
        props: {
            items:{type:Array},
            isDisabled: {
                type: Boolean,
                default: false
            },
            total_fourniture_prop: {
                type: Object
            }
        },
        data() {
            return {
                working_items:this.items,
                current_item: {},
                total_fourniture: this.total_fourniture_prop,
                designation_list: [],
                editingItem: false,
                editedIndex: null
            }
        },
        methods: {
            removeItem(index){
                this.$modal.show('dialog', {
                    title: 'Confirmation',
                    text: 'Voulez-vous supprimer cet élément?',
                    buttons: [
                        {
                            title: 'Non',
                            handler: () => this.$modal.hide('dialog')
                        },
                        {
                            title: 'Oui',
                            handler: () => {
                                this.working_items.splice(index, 1)
                                this.$modal.hide('dialog')
                                this.calculateMontantTotal()
                                this.calculateTotalVetuste()
                            }
                        }
                    ]
                })
            },
            editItem(index){
                this.current_item = JSON.parse(JSON.stringify(this.working_items[index]))
                this.editingItem = true
                this.editedIndex = index
            },
            saveEditItem(){
                this.current_item.montant = this.total
                this.working_items[this.editedIndex] = JSON.parse(JSON.stringify(this.current_item))
                this.current_item = {}
                this.editingItem = false
                this.editedIndex = null

                this.calculateMontantTotal()
                this.calculateTotalVetuste()
            },
            addItem(){
                console.log('addItem');
                if(!this.current_item.designation){
                    this.$notify({
                        group: 'user-message', type: 'error',
                        text: 'Désignation est obligatoire!'
                    });
                    return;
                }
                if(!_.isEmpty(this.current_item)){
                    this.current_item.montant = this.total
                    this.working_items.push(JSON.parse(JSON.stringify(this.current_item)))
                    this.current_item={};

                    this.calculateMontantTotal()
                    this.calculateTotalVetuste()
                }
            },
            getDesignations() {
                log('getDesignations');
                const data = {
                    collection: this.$SHARED.collections.designation,
                    query: {
                        type: 'fourniture'
                    },
                    options: {
                        page: 1, limit: -1, sort: {label: 1}
                    }
                };
                this.$store.requestFind(data).then(ar => {
                    this.designation_list = ar.docs;
                }, () => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error',
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
            calculateMontantTotal(){
                let totalMontant = 0;
                if(this.working_items){
                    this.working_items.forEach((item)=>{
                        totalMontant += parseFloat(item.montant)
                    })
                }
                this.total_fourniture.total_montant = totalMontant
                return totalMontant.toFixed(2);
            },
            calculateTotalVetuste(){
                if(this.working_items){
                    let totalVetuste = 0;
                    let vetuste =  0
                    this.working_items.forEach((item)=>{
                        if(item.vetuste){
                            vetuste = item.vetuste;
                        }
                        totalVetuste += (item.montant * vetuste) /100
                    })
                    this.total_fourniture.total_vetuste = totalVetuste
                    return totalVetuste.toFixed(2)
                }
            }
        },
        computed:{
            total(){
                if(this.current_item.prix_unitaire && this.current_item.quantite){
                    let total = this.current_item.prix_unitaire * this.current_item.quantite;

                    if((this.current_item.type === 'A' || this.current_item.type === 'O') && this.current_item.tva === "Oui"){
                        total += (total *20) / 100;
                    }

                    if(this.current_item.vetuste && this.current_item.vetuste !== 0)
                        total -= (total * this.current_item.vetuste) /100;
                    return total
                }
                return 0
            }
        },
        mounted() {
            this.getDesignations();
        }
    }
</script>

<style scoped>
    table th{
        line-height: 31px !important;
        padding-bottom: 0 !important;
    }
</style>